import React, { useRef, useEffect, useState } from "react";
import EventetSotCard from "../Eventet/EventetSotCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styles from "../../pages/evente.module.css";
import EventetCard2 from "../Eventet/EventetCard2";
import { useWindowDimensions } from "../../hooks/GetDimensions";

const SlickSlider = ({ data, cardType, events }) => {
  const slickRef = useRef(null);

  const { height, width } = useWindowDimensions();

  const settings = {
    // dots: true,
    // infinite: true,
    infinite: false,
    draggable: true,
    swipeToSlide: true,
    swipe: true,
    touchMove: true,
    lazyLoad: "ondemand",
    speed: 500,
    slidesToShow:
      events?.length >= 4
        ? 4
        : events?.length >= 3
        ? 3
        : events?.length >= 2
        ? 2
        : 1,
    slidesToScroll: 1,
    // nextArrow: <IoIosArrowForward />,
    // prevArrow: <IoIosArrowBack />,
    arrows: false,
    // arrows: true,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: events?.length >= 4 ? 4 : events?.length >= 3 ? 3 : 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1600,
        settings: {
          slidesToShow: events?.length >= 3 ? 3 : events?.length >= 2 ? 2 : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.sliderContainer}>
        <Slider id={styles.sliderMain} {...settings} ref={slickRef}>
          {events?.map((event) => (
            <EventetCard2
              key={event?.id}
              eventId={event?.id}
              title={event?.name}
              imageSrc={event?.images}
              linkTo={event?.id}
            />
          ))}
        </Slider>
      </div>

      {
        // cardType === "short" &&
        width > 1280 && (
          <div id={styles.nextBtn} onClick={() => slickRef.current.slickNext()}>
            <IoIosArrowForward />
          </div>
        )
      }

      {
        // cardType === "short" &&   ,,,,,,,   events?.length >= 4 &&
        width > 1280 && (
          <div id={styles.prevBtn} onClick={() => slickRef.current.slickPrev()}>
            <IoIosArrowBack />
          </div>
        )
      }
    </>
  );
};

export default SlickSlider;
